import Mutation from './mutationTypes';
import { apiSaveOnboardingDetails, apiUploadCompanyLogo, apiCreateSubscription, apiGetOptions } from "@/components/OnboardingAutomation/api"
export default {
    initializeOnboardingDetailsState: ({ commit, getters, state }, onboardingDetailsState) => {
        commit(Mutation.SET_INITIALIZE_ONBOARDING_DETAILS, onboardingDetailsState);     
        console.log('current setp ', state.currentStep);
        if(!getters.isLastStep(state.currentStep)) {
            console.log('is not last step ');
            commit(Mutation.SET_ITEM, {field: 'currentStep', value: getters.getNextStep});     
        }
    },
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    showHelp: ({ commit }, value) => {
        commit(Mutation.SET_ITEM, {field: 'activeHelp', value});
    },
    hideHelp:({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'activeHelp', value: ''});
    },
    setFormItem: ({ commit }, {step, field, value}) => {
        commit(Mutation.SET_FORM_ITEM, {step, field, value});
    },
    setError: ({ commit }, {step, field, value}) => {
        commit(Mutation.SET_ERROR, {step, field, value});
    },
    setWarning: ({ commit }, {step, field, value}) => {
        commit(Mutation.SET_WARNING, {step, field, value});
    },
    saveOnboardingDetails: async ({ commit, getters, state }) =>  {
        try {
            commit(Mutation.SET_ITEM, {field: 'savingDetails', value: true});
            commit(Mutation.SET_ITEM, {field: 'processing', value: true});
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'start'});
            let stateToSave = {...state};
            stateToSave.processing = false;
            stateToSave.savingDetails = false;
            stateToSave.progressStatus = '';
            stateToSave.error = '';
            stateToSave.warning = '';
            stateToSave.countries =[];
            stateToSave.industries=[];
            const response = await apiSaveOnboardingDetails(getters.getItem('caseId'), stateToSave);
            if(response && response.data) { //details saved successfull, clear the errors
                commit(Mutation.SET_ITEM, { field: 'error', value: '' });    
                console.log(response);
                if(getters.isLastStep(state.currentStep))  {
                    const createResponse = await apiCreateSubscription(getters.getItem('caseId'));
                    if(createResponse && createResponse.data) { //details saved successfull, clear the errors
                        commit(Mutation.SET_ITEM, { field: 'error', value: '' });    
                        commit(Mutation.SET_ITEM, { field: 'customerCreated', value: true });  
                        commit(Mutation.SET_ITEM, { field: 'sid', value: createResponse.data.sid });      
                        commit(Mutation.SET_ITEM, { field: 'subscriptionId', value: createResponse.data.subscriptionId });   
                        if(createResponse.data.warning) {
                            commit(Mutation.SET_ITEM, { field: 'warning', value: createResponse.data.warning });      
                        }
                    } else if(createResponse && createResponse.error) {
                        commit(Mutation.SET_ITEM, {field: 'error', value: createResponse.error});    
                    } else {  //there is no data in respoonse, so somethings wrong
                        let  error = {
                            [getters.getItem('currentStep')]: {
                                'savingError': 'Error occured while creating subscription, please try again later.'
                            }
                        };
                        commit(Mutation.SET_ITEM, {field: 'error', value: error });    
                    } 
                }
            } else if(response && response.error) {
                commit(Mutation.SET_ITEM, {field: 'error', value: response.error});    
            } else {  //there is no data in respoonse, so somethings wrong
                let  error = {
                    [getters.getItem('currentStep')]: {
                        'savingError': 'Error occured while saving details, please try again later.'
                    }
                };
                commit(Mutation.SET_ITEM, {field: 'error', value: error });    
            } 
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'complete'});
            commit(Mutation.SET_ITEM, {field: 'processing', value: false});
            commit(Mutation.SET_ITEM, {field: 'savingDetails', value: false});
        } catch (e) {
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'complete'});
            commit(Mutation.SET_ITEM, {field: 'processing', value: false});
            commit(Mutation.SET_ITEM, {field: 'savingDetails', value: false});
            console.log(e);
        }
    },
    uploadCompanyLogo: async ({ commit, getters }, file) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'uploadingLogo', value: true});
            commit(Mutation.SET_ITEM, {field: 'processing', value: true});
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'start'});
            const response = await apiUploadCompanyLogo(getters.getItem('caseId'), file);
            if(response && response.data) {
                commit(Mutation.SET_ITEM, {field: 'error', value: ''});    
                commit(Mutation.SET_FORM_ITEM, {step: 'company', field: 'logo', value: response.data.logoName});    
                commit(Mutation.SET_FORM_ITEM, {step: 'company', field: 'originalLogoFileName', value: response.data.originalLogoFileName});    
            }
            else if(response && response.error) {
                commit(Mutation.SET_ITEM, {field: 'error', value: response.error});    
            } else {
                commit(Mutation.SET_ERROR, {step: 'company', field: 'logo', value:'Logo upload failed!!'});    
            }
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'complete'});
            commit(Mutation.SET_ITEM, {field: 'uploadingLogo', value: false});
            commit(Mutation.SET_ITEM, {field: 'processing', value: false});
        } catch (e) {
            commit(Mutation.SET_ERROR, {step: 'company', field: 'logo', value:'Logo upload failed!!'});    
            commit(Mutation.SET_ITEM, {field: 'progressStatus', value: 'complete'});
            commit(Mutation.SET_ITEM, {field: 'uploadingLogo', value: false});
            commit(Mutation.SET_ITEM, {field: 'processing', value: false});
        }
    },
    async fetchOptions({commit}, type) {
        try {
            const response = await apiGetOptions(type);
            if(response && response.results) {
                commit(Mutation.SET_ITEM, {field: type, value: response.results});
            }
        } catch (error) {
            console.error(`Error fetching ${type} options:`, error);
        }
    },
    async setAllOptions({dispatch}) {
        try {
            await Promise.all([
                dispatch('fetchOptions', 'countries'),
                dispatch('fetchOptions', 'industries'),
            ]);
        } catch (error) {
            console.error('Error loading all options:', error);
        }
    }
}
