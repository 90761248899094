import axios from "@/plugins/axios";

export const apiGetOnboardingDetails = async caseId => {
    try {
        const response = await axios.get({ endpoint: '/management/onboarding/getDetails/' + caseId});
        if(response.data) {
            accordionsSettings = response.data;
            return (accordionsSettings.settings);
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
    return [];
}

export const apiSaveOnboardingDetails = async (caseId, details) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('details' , JSON.stringify(details));
        const response = await axios.post({
            endpoint: '/management/onboarding/saveCustomerDetails/'+ caseId,
            params: params,
            options: options
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log(e);
        console.log('There has been a problem with your fetch operation:'+ e.message);
    }
}

export const apiUploadCompanyLogo = async  (caseId, file) => {
    try {
       let formData = new FormData();
        formData.append('logo_file', file);
        let options = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axios.post({
            endpoint: '/management/onboarding/uploadCompanyLogo/'+ caseId,
            params: formData,
            options: options
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        if(e && e.response && e.response.data && e.response.data.includes('virus')) {
            return  { error: { company: { logo: 'Log file contains virus, please upload another logo.' } } };
        } else {
            console.log(e);
            console.log('There has been a problem with your upload logo operation:'+ e.message);
        }
        
    }
}


export const apiCreateSubscription = async (caseId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('caseId' , caseId);
        const response = await axios.post({
            endpoint: '/management/onboarding/process',
            params: params,
            options: options
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log(e);
        console.log('There has been a problem with your subscription creation operation:'+ e.message);
    }
}

export const apiGetOptions = async (type) => {
    try {
        const response = await axios.get({
            endpoint: '/management/onboarding/field/' + type
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log(e);
        console.log('There has been a problem onboarding options loading:'+ e.message);
    }
}