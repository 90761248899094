export default () => ({
    caseId: '',
    currentStep: 'company',
    allowGenericUserEmail: [],
    countries: [],
    industries: [],
    steps: {
        company: { 
            id: "company", 
            title: "Company details",
            fields: {
                logo: "",
                originalLogoFileName: "",
                corporateEmailDomain: "",
                subDomains: "",
                corporatePublicIp: "",
                country: "",
                industry: "",
            }
        },
        admin: { 
            id: "admin", 
            title: "Admin details",
            fields: {
                initial: "Mr",
                position: "",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                contactName: "",
                contactEmail: "",
            }
        },
        // sso: { 
        //     id: "sso", 
        //     title: "SSO details",
        //     fields:  {
        //         initial: "Mr",
        //         fullName: "",
        //         email: "",
        //     }
        // },
        accountInvoicing: { 
            id: "accountInvoicing", 
            title: "Account & invoicing details",
            fields:{
                number: "",
                companyName: "",
                fullName: "",
                contact: "",
                address: "",
                country: "Australia",
                postCode: "",
                email: "",
                state: "",
                suburbCity: ""
            }
        },
        internalAdmin: { 
            id: "internalAdmin", 
            title: "Company admin details",
            fields: {
                companyName: "",
                businessUnit: "",
                startDate: "",
                endDate: "",
                features: []
            }
         }
    },
    activeHelp: '',
    processing: false,
    uploadingLogo: false,
    savingDetails: false,
    progressStatus: '',
    error: '',
    warning: '',
    sid: '',
    subscriptionId: '',
    customerCreated: false
});
